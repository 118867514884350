<template>
  <div class="home">
    <h2>CTA Manager</h2>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>
