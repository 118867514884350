import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => {
        return {
          // all these properties will have their type inferred automatically
          wallet: '',
          isLogged: false,
        }
    },
  getters: {
    
  },
  actions: {

  },
})