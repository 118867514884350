<template>
  <div class="home">
    <AssetList/>
  </div>
</template>

<script>
// @ is an alias to /src
import AssetList from '@/components/AssetList.vue'

export default {
  name: 'Asset',
  components: {
    AssetList
  }
}
</script>
