<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
    <div class="text-h4 mr-4">CTA Manager</div>
    <div 
      v-for="item in appbarItem" 
      :key="item.title"
    >
      <v-btn 
          plain
          :to="item.link"
        ><v-icon class="mr-1">{{item.icon}}</v-icon>{{item.title}}
      </v-btn> |
    </div> 

      <v-spacer></v-spacer>

      <v-btn>
        <span v-if="!isLogged" class="mr-2" @click="connectWallet()">Connect Wallet</span>
        <span v-else @click="logout()">{{ walletCut }}</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { useUserStore } from './store/user'
import { Link } from '@imtbl/imx-sdk';
import { mapWritableState } from 'pinia';
export default {
  name: 'App',

  data: () => ({
    appbarItem:[
      {title:'My Asset', icon:"", link:"/asset"},
      {title:'Market', icon:"",link:"/market"},
      {title:'Trisel Value', icon:""}
    ]
  }),
  mounted(){
    let localStore=JSON.parse(localStorage.getItem('ctaMarket'))
    console.log(localStore.wallet)
    if(localStore.wallet!=null){
      this.wallet=localStore.wallet;
      this.isLogged=true
    } 
  },
  computed:{
    ...mapWritableState(useUserStore,[
      'wallet',
      'isLogged'
    ]),
    walletCut(){
      return this.wallet.substring(0,4) + '...' + this.wallet.substring(this.wallet.length-4)
    }
  },
  methods:{
    async connectWallet(){
      // Initialize Link
      let link = new Link('https://link.x.immutable.com')
      try{
          // Call the method
          let result = await link.setup({
            "providerPreference": "none"
          })
          localStorage.setItem('ctaMarket', JSON.stringify({"wallet":result.address,"isLogged":true}))
          this.wallet=result.address
          this.isLogged=true
      }catch(error){
          // Catch and print out the error
          console.error(error)
      }
    },
    logout(){
      this.wallet=''
      this.isLogged=false
    }
  }
};
</script>
