<template>
  <v-container class="fill-height">
    <v-responsive class="d-flex text-center fill-height">

      <h1 class="text-h2 font-weight-bold">CTA market</h1>

      <div class="py-14" />

      <v-row class="d-flex align-center justify-center">
        <v-col cols="12">
          <v-btn color="secondary" @click="getTradeList()">Récupérer les trades</v-btn>
          <v-card>
            <v-card-title>
              Trade
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="listAsset"
              :search="search"
              dense
              multi-sort
              show-group-by
            ></v-data-table>
          </v-card>
        </v-col>
        <v-col cols="auto">
          <v-card>
            <v-card-title>
              Stat
              <v-spacer></v-spacer>
              <v-switch
                v-model="switchfoil"
                :label="`Foil`"
              ></v-switch>
            </v-card-title>
            <v-data-table
              :headers="headersPrice"
              :items="averagePrice"
              dense
              multi-sort
              show-group-by
            ></v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-responsive>
  </v-container>
</template>

<script>
import { ImmutableX, Config } from '@imtbl/core-sdk';
const config = Config.PRODUCTION; // Or PRODUCTION
const client = new ImmutableX(config);
  export default {
  data() {
    return {
      listAsset:[],
      switchfoil:false,
      search:'',
      headers:[
        { text: 'order_id', align: 'end', value: 'order_id' },
        { text: 'status', align: 'end', value: 'status' },
        { text: 'token_id', align: 'end', value: 'sell.data.token_id' },
        { text: 'token_#mint', align: 'end', value: 'detail.metadata.numbering' },
        { text: 'token_name', align: 'end', value: 'detail.name' },
        { text: 'element', align: 'end', value: 'detail.metadata.element' },
        { text: 'power', align: 'end', value: 'detail.metadata.power' },
        { text: 'token_rarity', align: 'end', value: 'detail.metadata.rarity' },
        { text: 'token_grade', align: 'end', value: 'detail.metadata.grade' },
        { text: 'foil', align: 'end', value: 'detail.metadata.foil' },
        { text: 'buy type', align: 'end', value: 'buy.type' },
        { text: '$', align: 'end', value: 'montant' },
        {text:'date',value:'updated_timestamp'}
      ],
      headersPrice:[
        { text: 'rarity', align: 'end', value: 'rarity' },
        { text: 'grade', align: 'end', value: 'grade' },
        { text: 'total', align: 'end', value: 'total' },
        { text: 'nb', align: 'end', value: 'nb' },
        { text: 'moyenne', align: 'end', value: 'moyenne' },
      ],
      craftCost:[
        {rarity : "COMMON	", triselcost:"2000"},
        {rarity : "UNCOMMON", triselcost:"2500"},
        {rarity : "RARE", triselcost:"3500"},
        {rarity : "ULTRA_RARE", triselcost:"5300"},
        {rarity : "MYTHIC", triselcost:"7000"}
      ],
      craftDrop:[
        {rank : 'S', rate:0.01},
        {rank : 'A', rate:0.07},
        {rank : 'B', rate:0.22},
        {rank : 'C', rate:0.70},
      ]
    }
  },
  computed:{
    priceRarity(){
      const result=[]
      this.listAsset.filter(x=>x.detail.metadata.foil==this.switchfoil).reduce(function(res, value) {
        if (!res[value.detail.metadata.rarity]) {
          res[value.detail.metadata.rarity] = { Rarity: value.detail.metadata.rarity, montant: 0 , nb : 0,moyenne:0};
          result.push(res[value.detail.metadata.rarity])
        }
        res[value.detail.metadata.rarity].montant += parseInt(value.montant);
        res[value.detail.metadata.rarity].nb += 1
        res[value.detail.metadata.rarity].moyenne = (res[value.detail.metadata.rarity].montant/res[value.detail.metadata.rarity].nb).toFixed(2)
        return res;
      }, {});
      return result
    },
    averagePrice(){
      var helper = {};
      var result = this.listAsset.filter(x=>x.detail.metadata.foil==this.switchfoil).reduce(function(r, o) {
      var key=""
      var rarity=""
      var grade=""
        if(o.detail.metadata.tokenType==="BUNDLE"){
          key="BUNDLE"
          rarity = "BUNDLE"
          grade = "BUNDLE"
        }else if(o.detail.metadata.tokenType==="MINT_PASS"){
          key="MINT_PASS"
          rarity = "MINT_PASS"
          grade = "MINT_PASS"
        }else{
          key = o.detail.metadata.rarity + '-' + o.detail.metadata.grade;
          rarity = o.detail.metadata.rarity
          if (o.detail.metadata.grade==""){
           grade = "Standard"
          }else{
            grade = o.detail.metadata.grade
          }
        }
        if(!helper[key]) {
          helper[key] = { rarity: rarity, grade:grade, total: 0 , nb : 0, moyenne:0};// create a copy of o
          r.push(helper[key]);
        }
        if(helper[key]) {
          helper[key].total += parseInt(o.montant);
          helper[key].nb += 1;
        }
        return r;
      }, []).map(item=>{
        return{
            ...item,
            moyenne: Number(item.total/item.nb).toFixed(2),
          }
      })
      return result
    }
  },
  methods: {
    async getTradeList(){
      let assetCursor
      let trades = []
      do {
        const ordersRequest = await client.listOrders({
        status: 'filled',
        cursor : assetCursor,
        pageSize: 200,
        sellTokenAddress: '0xa04bcac09a3ca810796c9e3deee8fdc8c9807166',
        orderBy:'timestamp',
        minTimestamp:'2023-02-14T00:00:00.51988Z',
        maxTimestamp:'2023-02-14T23:00:00.51988Z'
      })
      trades = trades.concat(ordersRequest.result)
        assetCursor = ordersRequest.cursor
      } while (assetCursor)
      console.log(trades)
      const resultListAsset=await Promise.all(trades.map(async (item)=>{
        const assetRequest = await client.getAsset({
          tokenAddress:'0xa04bcac09a3ca810796c9e3deee8fdc8c9807166',
          tokenId: item.sell.data.token_id
        })
        const dataasset = await assetRequest
        let montant=0
        if(item.buy.type=="ETH"){
          montant= (parseInt(item.buy.data.quantity)/(Math.pow(10,item.buy.data.decimals))*1500).toFixed(2)
        }else{
          montant= (parseInt(item.buy.data.quantity)/(Math.pow(10,item.buy.data.decimals))).toFixed(2)
        }
          return{
            ...item,
            montant: Number(montant),
            detail:dataasset
          }
      }))
      this.listAsset=resultListAsset
    },
  }
}
</script>
