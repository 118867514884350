<template>
  <v-container>
    <v-row class="mt-3">
      <v-col>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="!loadingInventory">
        <v-data-table
          dense
          :headers="headers"
          :items="assets"
          :items-per-page="20"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:[`item.metadata.image`]="{ item }">
            <v-img
              max-height="100"
              max-width="50"
              :src=item.metadata.image
            >
            </v-img>
          </template>
          <template v-slot:[`item.metadata.rarity`]="{ item }">   
            <v-chip
              :color="getColorRarity(item.metadata.rarity)"
              dark
            >
              {{ item.metadata.rarity }}
            </v-chip>
          </template>
          <template v-slot:[`item.metadata.foil`]="{ item }">   
              <v-icon color="yellow darken-3">{{getFoil(item.metadata.foil)}}</v-icon>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">   
            {{item.created_at | formatDate}}
          </template>
        </v-data-table>
      </v-col>
      <v-col v-else>
        <v-skeleton-loader
          type="table-heading, list-item-two-line, image, table-tfoot"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { format,parseISO } from 'date-fns'
import { ImmutableX, Config } from '@imtbl/core-sdk';
import { useUserStore } from '../store/user'
import { mapState } from 'pinia';
const config = Config.PRODUCTION; // Or PRODUCTION
const client = new ImmutableX(config);
export default {
  name: 'AssetList',
  data: () => ({
    loadingInventory:false,
    search:"",
    headers:[
      {text: 'Id', value: 'token_id'},
      {text: 'Image', value: 'metadata.image'},
      {text: '#Mint', value: 'metadata.numbering'},
      {text: 'Name', value: 'metadata.name'},
      {text: 'Rarity', value: 'metadata.rarity'},
      {text: 'Rank', value: 'metadata.rank'},
      {text: 'Grade', value: 'metadata.grade'},
      {text: 'Foil', value: 'metadata.foil'},
      {text: 'Crée le', value: 'created_at'},
    ],
    assets:[]
  }),
  filters: {
    formatDate: function (value) {
      return format(parseISO(value), 'dd/MM/yyyy HH:mm')
    }
  },
  async mounted(){
    this.loadingInventory=true
      let assetCursor
      let assets = []
      const address='0xa04bcac09a3ca810796c9e3deee8fdc8c9807166'
      do {
        const collectionAssetsResponse = await client.listAssets({
          collection: address,
          pageSize: 200,
          cursor : assetCursor,
          user : JSON.parse(localStorage.getItem('ctaMarket')).wallet,
        });
        assets = assets.concat(collectionAssetsResponse.result)
        assetCursor = collectionAssetsResponse.cursor
      } while (assetCursor)
      this.assets=assets
      this.loadingInventory=false
  },
  computed:{
    ...mapState(useUserStore,[
      'wallet'
    ]),
  },
  methods:{
    getColorRarity(rarity){
      console.log(rarity)
      switch(rarity){
        case 'COMMON': return 'grey'
        case 'UNCOMMON': return 'green'
        case 'RARE': return 'blue'
        case 'ULTRA_RARE': return 'purple'
        case 'MYTHIC':return 'yellow darken-2'
        default: return 'transparent'
      }
    },
    getFoil(foil){
      if(foil){
        return 'mdi-check-bold'
      }else{
        return ''
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
